/* #region Main */

html {
  position: relative;
  min-height: 100%;
}

body {
  background-color: #fefefe;
  margin-bottom: 71px;
}

html body a {
  color: #1E9FF2;
}

  html body a:hover {
      color: #0C84D1;
  }

  html body a.disabled {
      pointer-events: none;
      cursor: default;
      color: #939393;
  }

.container-fluid {
  max-width: 1500px;
}

.container-page {
  max-width: 1400px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.modal-open {
  overflow: hidden !important; /* Prevents page shifting */
}

/* #endregion Main */

/* #region Header */

.header-bg {
  background: #252422;
  color: #fff;
  text-shadow: rgba(0,0,0,0.5) 0px 1px 2px;
  min-height: 71px;
  position: inherit !important;
  z-index: auto !important;
}

.header-img {
  background: #252422 url('../images/header_bg.jpg') no-repeat top left fixed;
}


@media screen and (max-width:768px) {
  .header-img {
      background: #252422;
  }
}

/* #region Navigation Bar */

.navbar {
  box-shadow: 0 0.5rem 1rem rgba(0,0,0,.05), inset 0 -1px 0 rgba(0,0,0,.1) !important;
}

.navbar-dark {
  background-color: #222 !important;
  border-color: #080808;
}

.navbar-nav .nav-link {
  padding-bottom: 0;
  padding-top: 0;
}

.navbar-dark .navbar-nav .nav-link,
.nav-custom-link, .nav-custom-link {
  color: #9d9d9d;
}

  .nav-custom-link:hover, .nav-custom-link:active, .nav-custom-link:focus {
      color: rgba(255,255,255,.75);
      background-color: transparent;
      text-decoration: none;
      outline: 0;
  }

#SubNav {
  z-index: 1001;
}

/* #endregion Navigation Bar */

/* #endregion Header */

/* #region Breadcrumbs */

.breadcrumb {
  padding: .4rem 1rem;
  background: none;
}

/* #endregion Breadcrumbs */

/*  #region Footer  */

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background-color: #252422;
  color: #fff;
}

/* #endregion Footer */

/* #region DataTable Selections */

table.dataTable tbody tr.selected a, table.dataTable tbody th.selected a, table.dataTable tbody td.selected a {
  color: white !important;
}

table.dataTable tbody > tr.selected, table.dataTable tbody > tr > .selected {
  background-color: rgba( 2, 117, 216, 0.7) !important;
}

table.dataTable tbody td.select-checkbox, table.dataTable thead th.select-checkbox {
  min-width: 25px;
}

/* #endregion DataTable Selections */

/* #region Reports */

.info, .group-item-expander {
  background-color: #d9edf7 !important;
  font-weight: bold;
}

.group-item-expander {
  text-align: left !important;
  cursor: pointer;
}

  .group-item-expander td {
      background-color: #d9edf7;
  }

      .group-item-expander td:first-child {
          text-align: left;
      }

.group-by-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.group-by-filter-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}

@media screen and (max-width:525px) {
  #RangePickers {
      line-height: 1rem !important;
      font-size: 85% !important;
  }
}

.datepicker-dropdown {
  z-index: 1020 !important;
}

/* #endregion Reports */

/* #region Div Grid */

.grid-container {
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background: #F9F9F9;
  margin-bottom: 1em;
}

  .grid-container .row div {
      border: 1px solid #ddd;
      border-bottom: 0;
      border-left: 0;
      line-height: 2.5em;
      padding: 0.5em;
  }

/* #endregion Div Grid */

/* #region Tab Navigation */

.nav-tabs .nav-link {
  border: none;
}

.nav-tabs a.nav-link {
  border: 0px;
  padding: 10px 20px;
  color: #54667a;
}

  .nav-tabs a.nav-link:hover {
      color: #FF1635;
  }

  .nav-tabs a.nav-link.active {
      border-bottom: 2px solid #FF1635;
      color: #FF1635;
  }

@media screen and (max-width:768px) {
  .nav-tabs a.nav-link {
      padding: 5px 10px;
  }
}

/* #endregion Tab Navigation */

/* #region Validation */

/* Error Summary */

.validation-summary-valid {
  display: none;
}

.validation-summary-errors.text-danger {
  color: #a94442;
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid transparent;
  border-radius: 3px;
  border-color: #ffeaea;
  background-color: rgba(255,234,234,.8);
}

.validation-summary-errors.span {
  padding-left: 15px;
}

.validation-summary-errors.text-danger ul {
  margin-bottom: 0;
}

/* Error Text */
.text-danger {
  font-size: 90%;
  color: #fd0000;
}

/* Text Input Error */

.form-control.input-validation-error {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

  .form-control.input-validation-error:focus {
      border-color: #dc3545;
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }

textarea.form-control.input-validation-error {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

/* Select Error */

.custom-select.input-validation-error {
  border-color: #dc3545;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23343a40'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat right 0.75rem top 0.95rem/10px 9.5px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

  .custom-select.input-validation-error:focus {
      border-color: #dc3545;
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }

/* Selectpicker Error */

.dropdown-toggle.input-validation-error {
  padding-right: 0.75rem !important;
  background-position: center right 1.75em !important;
}

.form-check-input.input-validation-error ~ .form-check-label {
  color: #dc3545;
}

.custom-control-input.input-validation-error ~ .custom-control-label {
  color: #dc3545;
}

  .custom-control-input.input-validation-error ~ .custom-control-label::before {
      border-color: #dc3545;
  }

.custom-control-input.input-validation-error:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.custom-control-input.input-validation-error:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.custom-control-input.input-validation-error:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.custom-file-input.input-validation-error ~ .custom-file-label {
  border-color: #dc3545;
}

.custom-file-input.input-validation-error:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

/*#endregion Validation*/

/* #region Loading */

#loader-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(33, 33, 33, 0.75);
  z-index: 10000;
}

  #loader-container .spinner-border {
      width: 5rem;
      height: 5rem;
      position: absolute;
      top: calc(50% - 2.5rem);
      left: calc(50% - 2.5rem);
      color: white;
      border-width: .3rem;
  }

/* Modal Loading */
#LoadingIcon {
  display: none;
  margin-right: 10px;
  color: #252422;
}

/* #endregion Loading */

/* #region Card */

.card-header {
  background-color: #343a40;
  border-color: #454d55;
  color: #fff;
  padding: .5rem .75rem;
}

.card, .wrapper {
  border: 0;
  border-radius: 0px;
  -webkit-box-shadow: 1px 1px 8px 1px rgba(0,0,0,.04);
  box-shadow: 1px 1px 8px 1px rgba(0,0,0,.04);
  -webkit-transition: .5s;
  transition: .5s;
  background: #fff;
}

.card-header {
  border-radius: 0px !important;
}

/* #endregion Card */

/* #region Combo Box  */

.combo-box-container {
  width: 0px !important;
  height: 0px !important;
  float: right;
  margin-right: 2.68rem;
  top: -2.33rem;
  position: relative;
}

  .combo-box-container .btn-default {
      color: #495057 !important;
  }

  .combo-box-container .bootstrap-select {
      width: 42px !important;
      height: 38px !important;
  }

      .combo-box-container .bootstrap-select button {
          width: 42px !important;
          height: 37px !important;
          padding-right: 26px;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0;
      }

.hide-dropdown-toggle.dropdown-toggle:after {
  color: #E9ECEF !important;
}

/* #endregion Combo Box */

/* #region Select  */

select.input-sm {
  padding-left: 5px;
}

.custom-select, .custom-select-sm {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%23343a40'><polygon points='0,0 100,0 50,50'/></svg>");
  background-repeat: no-repeat;
}

.custom-select {
  background-position: right 0.75rem top 0.95rem;
  background-size: 10px 9.5px;
}

.custom-select-sm {
  background-position: right 0.75rem top 0.8rem;
  background-size: 10px 9.5px;
  padding: .25rem 2rem .25rem .5rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Selectpicker Button Style */
.btn-input {
  color: #495057;
  background-color: #fff;
  border-color: #ced4da;
}

/* #endregion Select */

/* #region Charts  */

.flot-chart {
  display: block;
  height: 400px;
}

.flot-chart-content {
  width: 100%;
  height: 100%;
}

.legendLabel {
  padding-left: 4px;
  color: #333;
}

.label-shift .xAxis .flot-tick-label.tickLabel {
  margin-left: 3em;
}

/* #endregion Graphs */

/* #region Ticket Detail Page */

.summary-grid {
  overflow-x: hidden;
  border: 1px solid #ddd;
  padding-top: 1em;
  padding-bottom: 1em;
}

  .summary-grid li {
      display: block;
      float: left;
      width: 25%;
  }

  .summary-grid > .col2 {
      width: 50%;
  }

  .summary-grid > .col3 {
      width: 75%;
  }

  .summary-grid > .col4 {
      width: 100%;
  }

.summary-grid-header {
  margin-top: 0.5em;
  font-size: 14px;
  font-style: italic;
}

.ticket-detail-header {
  font-size: 18px;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
}

/* #endregion Ticket Detail Page  */

/* #region Tables */

.table-striped > tbody > tr:nth-child(2n+1) {
  background-color: rgba(0,0,0,.035);
}

.table thead th {
  vertical-align: top;
}

.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th {
  border: none;
}

/* #endregion Tables */

/* #region Pagination */

@media screen and (max-width:768px) {
  .pagination .page-link {
      padding: .25rem .5rem;
      font-size: .875rem;
      line-height: 1.5;
  }
}

/* #endregion Pagination */

/* #region Single Property CSS Helpers */

.pointer {
  cursor: pointer;
}

.disable-user-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}

/* #endregion Helpers */

/*  #region Forms.js  */

.ValueChanged {
  background: #ffeb90;
}

.VoidRow {
  text-decoration: line-through;
}

/*  #endregion Forms.js  */

/* Misc */

label {
  margin-bottom: .1rem;
}

.numeric-col {
  text-align: right !important;
}

.no-data-message {
  font-style: italic;
}

.DragBox {
  padding: .3em .5em;
  background: #CCCCCC;
  border: solid 1px #000;
  display: block;
  margin: 0.5em;
}

  .DragBox:hover {
      background: #FFFACD;
  }

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}